<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :isShow="isShow" :btn="btn" :strForm="ppayForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('ppayForm')" />
      <div class="vd_export" v-if="isShow">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
      </div>
      <div class="vd_export2 vg_mr_8">
        <span style="margin-right: 2px">付款单号:</span>
        <el-input size="mini" style="width: 150px" disabled v-model="ppayForm.ppay_no" show-word-limit> </el-input>
      </div>
    </div>
    <el-form ref="ppayForm" :model="ppayForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="用友对接状态" prop="yongYouStatus" v-if="yongYouStatus === '已抓取'">
            <el-input v-model="yongYouStatus" type="input" disabled> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item :label="ppayForm.autoInc ? '(旧)供应商简称' : '供应商简称'" prop="supp_abbr">
            <el-input disabled v-model="ppayForm.supp_abbr" maxlength="20" show-word-limit placeholder="请填写供应商简称"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收票状态">
            <el-select size="small" v-model="ppayForm.pinv_invstat" placeholder="请选择收票状态">
              <el-option v-for="item in invstatList" :key="item.id" :label="item.value" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item :rules="[{ required: ppayForm.status === 1, message: ' ' }]" label="实际付款日" prop="ppay_rpdate">
            <el-date-picker v-model="ppayForm.ppay_rpdate" type="date" placeholder="选择实际付款日"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="付款金额" prop="ppay_total">
            <el-input :value="clac_ppay_part_pay" disabled maxlength="10" show-word-limit placeholder="请填写付款金额"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="币种" prop="cust_currency">
            <el-select v-model="ppayForm.cust_currency" size="small" placeholder="请选择币种" filterable>
              <el-option v-for="item in custCurrencyList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--        <el-col :md="8">
          <el-form-item label="希望付款日:" prop="ppay_ppdate">
            <el-date-picker v-model="ppayForm.ppay_ppdate" type="date" placeholder="选择希望付款日"> </el-date-picker>
          </el-form-item>
        </el-col>-->
        <el-col :md="8">
          <el-form-item label="发票日期:" prop="pinv_date">
            <el-date-picker disabled v-model="ppayForm.pinv_date" type="date" placeholder="选择发票日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="本币金额" prop="ppay_rmbtotal">
            <el-input :value="clac_ppay_rmbtotal" disabled maxlength="10" show-word-limit placeholder="请填写本币金额"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="汇率" prop="ppay_rmbrate">
            <el-input
              @change="ppayRmbrateChange"
              v-model="ppayForm.ppay_rmbrate"
              @input="val => (ppayForm.ppay_rmbrate = keep6Decimal(val))"
              maxlength="9"
              show-word-limit
              placeholder="请填写汇率"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="发票种类">
            <el-select size="small" v-model="ppayForm.ppay_invtype" placeholder="请选择发票种类">
              <el-option v-for="item in invtypeList" :key="item.id" :label="item.value" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="外销抬头" prop="cptt_bid">
            <el-select v-model="ppayForm.cptt_bid" size="small" placeholder="请选择外销抬头" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_aid">
            <el-select v-model="ppayForm.cptt_aid" size="small" placeholder="请选择公司抬头" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="所属部门" prop="cust_dept_id">
            <el-select v-model="ppayForm.cust_dept_id" size="small" placeholder="请选择所属部门" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="出运发票号" prop="tran_nos">
            <el-input
              disabled
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6 }"
              v-model="ppayForm.tran_nos"
              maxlength="255"
              show-word-limit
              placeholder="请填写出运发票号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="采购合同号" prop="podr_nos">
            <el-input
              disabled
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 6 }"
              v-model="ppayForm.podr_nos"
              maxlength="255"
              show-word-limit
              placeholder="请填写采购合同号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="采购发票号" prop="pinv_actu_nos">
            <el-input
              disabled
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }"
              v-model="ppayForm.pinv_actu_nos"
              maxlength="255"
              show-word-limit
              placeholder="请填写采购发票号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <PpayPartList :ppayForm="ppayForm"></PpayPartList>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="ppayForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { ppayAPI } from '@api/modules/ppay';
import { optnAPI } from '@api/modules/optn';
import { cpttAPI } from '@api/modules/comptitle';
import { stffAPI } from '@/api/modules/staff';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import PpayPartList from './Component/PpayPartList.vue';
import { BigNumber } from 'bignumber.js';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import { keep6Decimal } from '@assets/js/regExUtil';
import { deptAPI } from '@api/modules/department';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ppayEditMain',
  components: {
    editHeader,
    inputUser,
    PpayPartList
  },
  data() {
    return {
      rules: {
        ppay_type: []
      },
      ppayForm: {
        supp_abbr_rich: null
      },
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      cpttList: [],
      custCurrencyList: [],
      deptList: [],
      invstatList: [
        { id: 0, value: '未收票' },
        { id: 1, value: '已收票' }
      ],
      invtypeList: [
        { id: 1, value: '增值税发票' },
        { id: 2, value: '普通发票' }
      ],
      yongYouStatus: '未抓取'
    };
  },
  created() {
    this.initData();
  },
  computed: {
    clac_ppay_part_pay() {
      //判断是否是同步的数据
      if (this.ppayForm.autoInc) {
        return this.ppayForm.ppay_total;
      }
      let reduce = 0;
      if (this.ppayForm.ppay_part_list) {
        reduce = objectArrayReduce(this.ppayForm.ppay_part_list, 'ppay_part_pay', 2);
        this.ppayForm.ppay_total = reduce;
      }
      return reduce;
    },
    clac_ppay_rmbtotal() {
      let temp = new BigNumber(this.clac_ppay_part_pay).times(this.ppayForm.ppay_rmbrate).toFixed(2);
      this.ppayForm.ppay_rmbtotal = temp;
      return temp;
    }
  },
  watch: {},
  methods: {
    keep6Decimal,
    async initData() {
      this.loadingFlag = true;
      this.getPpayInfo();
      this.getCptt();
      this.getStffUser();
      this.getProdCurrency();
      this.getDept();
    },
    getDept() {
      getNoCatch(deptAPI.getAllDeptsV1).then(({ data }) => {
        this.deptList = Array.from(data, ({ dept_id, dept_name }) => {
          return { dept_id: dept_id, dept_name: dept_name };
        });
      });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let ppayForm = JSON.parse(JSON.stringify(this.ppayForm));
      if (ppayForm.ppay_rpdate) {
        ppayForm.ppay_rpdate = new Date(ppayForm.ppay_rpdate).getTime() / 1000;
      }
      if (ppayForm.ppay_ppdate) {
        ppayForm.ppay_ppdate = new Date(ppayForm.ppay_ppdate).getTime() / 1000;
      }
      if (ppayForm.pinv_date) {
        ppayForm.pinv_date = new Date(ppayForm.pinv_date).getTime() / 1000;
      }
      post(ppayAPI.editPpay, ppayForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取币种
    getProdCurrency() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCurrencyList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 计算本币金额
    ppayRmbrateChange() {
      let totalA = new BigNumber(1);
      totalA = totalA.times(this.ppayForm.ppay_total).times(this.ppayForm.ppay_rmbrate).toNumber();
      this.ppayForm.ppay_rmbtotal = this.helper.haveFour(totalA);
    },
    // 获取表单信息
    getPpayInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(ppayAPI.getPpayById, { ppay_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.ppayForm = res.data.data.form;
            this.btn = res.data.data.btn;
            if (this.ppayForm.autoInc && this.ppayForm.autoInc !== 1) this.btn.edit = false;
            this.stffForm.stff_name = this.ppayForm.stff_name;
            this.stffForm.dept_name = this.ppayForm.dept_name;
            this.stffForm.dept_team_name = this.ppayForm.dept_team_name;
            this.stffForm.user_id = this.ppayForm.user_id;
            this.stffForm.dept_id = this.ppayForm.dept_id;
            this.stffForm.stff_id = this.ppayForm.stff_id;
            if (this.ppayForm.pinv_invstat === 0) {
              this.ppayForm.pinv_invstatCopy = '未收票';
            } else if (this.ppayForm.pinv_invstat === 1) {
              this.ppayForm.pinv_invstatCopy = '已收票';
            }
            if (!this.ppayForm.ppay_rpdate) {
              this.ppayForm.ppay_rpdate = null;
            } else {
              this.ppayForm.ppay_rpdate = this.ppayForm.ppay_rpdate * 1000;
            }
            if (!this.ppayForm.ppay_ppdate) {
              this.ppayForm.ppay_ppdate = null;
            } else {
              this.ppayForm.ppay_ppdate = this.ppayForm.ppay_ppdate * 1000;
            }
            if (!this.ppayForm.pinv_date) {
              this.ppayForm.pinv_date = null;
            } else {
              this.ppayForm.pinv_date = this.ppayForm.pinv_date * 1000;
            }
            if (this.ppayForm.autoInc) {
              //用同步的数据生成付款单取值老ERP简称
              this.ppayForm.supp_abbr = this.ppayForm.supp_abbr_rich;
            }
            //用友抓取后不可反生效不可编辑
            this.$emit('isYongYou', false);
            this.yongYouStatus = '未抓取';
            this.ppayForm.ppay_part_list.forEach(item => {
              if (item.nap_CloseBill_FK_Status === 1) {
                this.btn.edit = false;
                this.$emit('isYongYou', true);
                this.yongYouStatus = '已抓取';
              }
            });
            /*            let temp = new BigNumber(this.ppayForm.ppay_total);
            this.ppayForm.ppay_rmbtotal = temp.times(this.ppayForm.ppay_rmbrate).toNumber();
            for (let i = 0; i < this.ppayForm.ppay_part_list.length; i++) {
              let totalA = new BigNumber(0);
              let totalB = new BigNumber(this.ppayForm.ppay_part_list[i].ppay_part_subtotal);
              totalA = totalA.plus(this.ppayForm.ppay_part_list[i].ppay_part_pay ? this.ppayForm.ppay_part_list[i].ppay_part_pay : 0);
              totalB = totalB.minus(this.ppayForm.ppay_part_list[i].ppay_part_pay ? this.ppayForm.ppay_part_list[i].ppay_part_pay : 0);
              totalA = totalA.toNumber();
              totalB = totalB.toNumber();
              this.ppayForm.ppay_part_list[i].ppay_part_paid = this.helper.haveFour(totalA);
              this.ppayForm.ppay_part_list[i].ppay_part_unpaid = this.helper.haveFour(totalB);
              this.ppayForm.ppay_part_list[i].ppay_part_pay = this.helper.haveFour(this.ppayForm.ppay_part_list[i].ppay_part_pay);
              this.ppayForm.ppay_part_list[i].ppay_part_subtotal = this.helper.haveFour(this.ppayForm.ppay_part_list[i].ppay_part_subtotal);
              this.ppayForm.ppay_part_list[i].prod_num = this.helper.haveFour(this.ppayForm.ppay_part_list[i].prod_num);
              this.ppayForm.ppay_part_list[i].prod_mtrb_price = this.helper.haveFour(this.ppayForm.ppay_part_list[i].prod_mtrb_price);
              this.ppayForm.ppay_part_list[i].prod_tgweight = this.helper.reservedDigits(this.ppayForm.ppay_part_list[i].prod_tgweight);
              this.ppayForm.ppay_part_list[i].prod_tnweight = this.helper.reservedDigits(this.ppayForm.ppay_part_list[i].prod_tnweight);
            }*/
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {});
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
.vd_word {
  word-wrap: break-word;
  word-break: normal;
}
.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>
