<template>
  <div>
    <el-table :data="ppayForm.ppay_part_list" border :summary-method="getSummaries" show-summary>
      <el-table-column label="序号" width="60" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="出运发票号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.tran_no'">
            <el-input :value="ppayForm.ppay_part_list[scope.$index].tran_no" disabled maxlength="20" show-word-limit placeholder="暂无出运发票号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="采购合同号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.podr_no'">
            <el-input v-model="ppayForm.ppay_part_list[scope.$index].podr_no" disabled maxlength="20" show-word-limit placeholder="暂无采购合同号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="我司货号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_no'">
            <el-input v-model="ppayForm.ppay_part_list[scope.$index].prod_no" disabled maxlength="20" show-word-limit placeholder="暂无我司货号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column label="货号后缀" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_poststfix'">
            <el-input
              v-model="ppayForm.ppay_part_list[scope.$index].prod_poststfix"
              disabled
              maxlength="20"
              show-word-limit
              placeholder="暂无货号后缀"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>-->
      <el-table-column label="开票品名" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_partno'">
            <el-input v-model="ppayForm.ppay_part_list[scope.$index].prod_partno" disabled maxlength="20" show-word-limit placeholder="暂无部件名"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_cust_no'">
            <el-input
              v-model="ppayForm.ppay_part_list[scope.$index].prod_cust_no"
              disabled
              maxlength="20"
              show-word-limit
              placeholder="暂无客户货号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="中文名称" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_hsname'">
            <el-input v-model="ppayForm.ppay_part_list[scope.$index].prod_hsname" disabled maxlength="20" show-word-limit placeholder="暂无中文名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量" width="200" prop="prod_num">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_num'">
            <el-input v-model="ppayForm.ppay_part_list[scope.$index].prod_num" disabled maxlength="20" show-word-limit placeholder="暂无数量"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单价" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_mtrb_price'">
            <el-input v-model="ppayForm.ppay_part_list[scope.$index].prod_mtrb_price" disabled maxlength="20" show-word-limit placeholder="暂无单价"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="总毛重" width="200" prop="prod_tgweight">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_tgweight'">
            <el-input v-model="ppayForm.ppay_part_list[scope.$index].prod_tgweight" disabled maxlength="20" show-word-limit placeholder="暂无总毛重"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="总净重" width="200" prop="prod_tnweight">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_tnweight'">
            <el-input v-model="ppayForm.ppay_part_list[scope.$index].prod_tnweight" disabled maxlength="20" show-word-limit placeholder="暂无总净重"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="开票数量单位" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.pinv_part_unit'">
            <el-input
              v-model="ppayForm.ppay_part_list[scope.$index].pinv_part_unit"
              disabled
              maxlength="20"
              show-word-limit
              placeholder="暂无开票数量单位"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="应付金额" width="200" prop="ppay_part_subtotal">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.ppay_part_subtotal'">
            <el-input
              v-model="ppayForm.ppay_part_list[scope.$index].ppay_part_subtotal"
              disabled
              maxlength="20"
              show-word-limit
              placeholder="暂无应付金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="已付金额" width="200" prop="ppay_part_paid">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.ppay_part_paid'">
            <el-input
              v-model="ppayForm.ppay_part_list[scope.$index].ppay_part_paid"
              disabled
              maxlength="20"
              show-word-limit
              placeholder="暂无已付金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="未付金额" width="200" prop="ppay_part_unpaid">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.ppay_part_unpaid'">
            <el-input
              v-model="ppayForm.ppay_part_list[scope.$index].ppay_part_unpaid"
              disabled
              maxlength="20"
              show-word-limit
              placeholder="暂无未付金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="本次付款金额" width="200" prop="ppay_part_pay">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.ppay_part_pay'">
            <el-input
              v-model="ppayForm.ppay_part_list[scope.$index].ppay_part_pay"
              @change="ppayPartPayChange(scope.$index)"
              @input="val => (ppayForm.ppay_part_list[scope.$index].ppay_part_pay = keep2Decimal(val))"
              maxlength="13"
              show-word-limit
              placeholder="暂无本次付款金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="税率" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.ppay_part_taxrate'">
            <el-input
              show-word-limit
              size="small"
              maxlength="9"
              @input="val => (ppayForm.ppay_part_list[scope.$index].ppay_part_taxrate = keep2Decimal(val))"
              v-model="ppayForm.ppay_part_list[scope.$index].ppay_part_taxrate"
              placeholder="暂无税率"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="备注" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.ppay_part_remark'">
            <el-input
              v-model="ppayForm.ppay_part_list[scope.$index].ppay_part_remark"
              type="textarea"
              :rows="2"
              maxlength="50"
              show-word-limit
              placeholder="暂无备注"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- <el-table-column label="产品描述" width="200">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'ppay_part_list.' + scope.$index + '.prod_desc'">
            <el-input v-model="ppayForm.ppay_part_list[scope.$index].prod_desc" disabled maxlength="20" show-word-limit
              placeholder="暂无产品描述">
              <template slot="append">
                  <el-popover
                    placement="bottom"
                    width="600"
                    @show="textEnlargementShow(0,scope.$index)"
                    trigger="click">
                      <textEnlargement ref="textEnlargement" :disabledFlag="true" @textEnlargementChange="textEnlargementChange" :textEnlargementString="textEnlargementString"></textEnlargement>
                    <span slot="reference"><el-link type="info">...</el-link></span>
                  </el-popover>
                </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import { keep2Decimal, keep4Decimal } from '@assets/js/regExUtil';

export default {
  name: 'PpayPartList',
  props: {
    ppayForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      prodUnitList: []
    };
  },
  components: {
    // textEnlargement
  },
  created() {
    this.initData();
  },
  methods: {
    keep2Decimal,
    keep4Decimal,
    initData() {},
    // 更换付款金额
    ppayPartPayChange(val) {
      if (Number(this.ppayForm.ppay_part_list[val].ppay_part_pay) > Number(this.ppayForm.ppay_part_list[val].ppay_part_subtotal)) {
        this.ppayForm.ppay_part_list[val].ppay_part_pay = this.ppayForm.ppay_part_list[val].ppay_part_subtotal;
      }
      let totalA = new BigNumber(0);
      let totalB = new BigNumber(this.ppayForm.ppay_part_list[val].ppay_part_subtotal);
      totalA = totalA.plus(this.ppayForm.ppay_part_list[val].ppay_part_pay ? this.ppayForm.ppay_part_list[val].ppay_part_pay : 0);
      totalB = totalB.minus(this.ppayForm.ppay_part_list[val].ppay_part_pay ? this.ppayForm.ppay_part_list[val].ppay_part_pay : 0);
      totalA = totalA.toNumber();
      totalB = totalB.toNumber();
      this.ppayForm.ppay_part_list[val].ppay_part_paid = new BigNumber(totalA).toFixed(2);
      this.ppayForm.ppay_part_list[val].ppay_part_unpaid = new BigNumber(totalB).toFixed(2);
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.ppayForm.ppay_part_list[val1].prod_desc;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.ppayForm.ppay_part_list[this.textEnlargementNum].prod_desc = val;
      }
    },
    // // 计算价格保留四位
    calcPrice(num, posi = 0, str) {
      let num1 = 0;
      let fre = null;
      if (posi % 3 === 1) {
        fre = Math.pow(10, posi + 1);
      } else {
        fre = Math.pow(10, posi);
      }
      num1 = (num * fre) / fre;
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.0001;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.0000';
            } else {
              if (value.length - 1 - ind > 4) {
                return value.substr(0, ind + 5);
              } else if (value.length - 1 - ind < 4) {
                let index = 4 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    },
    // 计算价格保留2位
    calcPriceT(num, posi = 0, str) {
      let num1 = 0;
      num1 = Number(num);
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.01;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.00';
            } else {
              if (value.length - 1 - ind > 2) {
                return value.substr(0, ind + 3);
              } else if (value.length - 1 - ind < 2) {
                let index = 2 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          let initValue = new BigNumber(0);
          sums[index] = values
            .reduce((x, item) => {
              return x.plus(item);
            }, initValue)
            .toFixed(2);
        } else {
          sums[index] = '';
        }
      });
      return sums;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
</style>
